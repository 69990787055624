<template>
  <categoriasList />
</template>

<script>

const categoriasList = () => import('@/layouts/components/Categorias/CategoriasList.vue')

export default {
  components: {
    categoriasList,
  },
}
</script>

<style>

</style>
